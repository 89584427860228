import React from 'react';
import styled from '@emotion/styled';
import fonts from 'style/fonts';
import { text } from 'style/mixin';
import sizes from 'style/sizes';
import colors from 'style/colors';
import { css } from '@emotion/core';

type ListProps = {
  /** 連番 */
  ordered?: boolean;
  /** 注釈 */
  note?: boolean;
  /** listのテキスト */
  txts?: string[];
} & JSX.IntrinsicElements['ul'] &
  JSX.IntrinsicElements['ol'];

const Container = styled.ul<ListProps>`
  font-family: ${fonts.jaGothic};
  letter-spacing: 0.04em;
  ${props =>
    props.note
      ? css`
          ${text(12, 24 / 12)}
        `
      : css`
          ${text(15, 32 / 15)}
        `}
  ul&,
  ol& {
    padding: 0;
    > li {
      padding-left: ${sizes.object[32]};
      position: relative;
      &::before {
        position: absolute;
        left: 0;
      }
      & + li {
        margin-top: 0.4em;
      }
    }
  }
  ul& {
    > li {
      ${props =>
        props.note
          ? css`
              padding-left: ${sizes.object[15]};
              &::before {
                content: '*';
              }
            `
          : css`
              &::before {
                content: '';
                top: 1em;
                width: ${sizes.object[15]};
                height: 1px;
                background-color: ${colors.gray02};
              }
            `}
      // 何故かエラーがでるため
      &::before {
      }
    }
  }

  ol& {
    counter-reset: list;
    > li {
      counter-increment: list;
      &::before {
        font-family: ${fonts.enGothic};

        top: 0;
        color: #a3a3a3;
      }
      ${props =>
        props.note
          ? css`
              padding-left: 24px;
              &::before {
                font-family: ${fonts.jaGothic};
                content: '*' counter(list);
              }
            `
          : css`
              &::before {
                content: counter(list, decimal-leading-zero);
              }
            `}
    }
  }
`;

/**
 * Listコンポーネント
 * 通常の文字列のみであれば`txts`propsで配列で流し込めます。
 * 別途childreとして流すことも可能です。
 */
export const List: React.FC<ListProps> = ({ txts, ...props }) => {
  const Tagname = props.ordered ? Container.withComponent('ol') : Container;

  return (
    <Tagname {...props}>
      {txts && txts.map((txt, idx) => <li key={idx}>{txt}</li>)}
      {!txts && props.children}
    </Tagname>
  );
};
