import * as React from 'react';
import styled from '@emotion/styled';
import sizes from 'style/sizes';
import { headerHeightPc, headerHeightSp, mq } from 'style/variables';
import InquiryBlock from 'components/organisms/inquiryBlock/';
import { TextSet } from 'components/molecules/textSet/';
import { JaHeading } from 'components/atoms/jaHeading/';
import { List } from 'components/atoms/list/';
import { Text } from 'components/atoms/text/';
import { EnHeading } from 'components/atoms/enHeading';
import { graphql } from 'gatsby';
import SEO from 'util/seo';
import { useEffect } from 'react';
import ParticleImageManager from 'components/organisms/particleCanvas/ParticleImageManager';
import { contentWrapper } from 'style/mixin';

// Please note that you can use https://github.com/dotansimha/graphql-code-generator
// to generate all types from graphQL schema
interface IndexPageProps {
  data: {
    site: {
      siteMetadata: {
        title: string;
      };
    };
  };
}

const PrivacyWrap = styled.div`
  ${contentWrapper}
`;

const TitleSection = styled.div`
  ${mq.onlypc} {
    padding-top: ${sizes.margin[180]};
    padding-bottom: ${sizes.margin[100]};
  }
  ${mq.onlysp} {
    padding-top: ${sizes.margin[155]};
    padding-bottom: ${sizes.margin[64]};
  }
`;

const PrivacySectionWrap = styled.div`
  padding-bottom: ${sizes.margin[100]};
`;

const PrivacySection = styled.div`
  ${mq.onlypc} {
    max-width: 700px;
    margin: 0 auto;
  }
  ul {
    margin-top: ${sizes.margin[32]};
  }
`;

const Pages: React.FC<IndexPageProps> = () => {
  useEffect(() => {
    ParticleImageManager.loadAll().then(() => {
      ParticleImageManager.generateAll().then(() => {
        ParticleImageManager.start();
      });
    });

    return () => {
      ParticleImageManager.delete();
    };
  }, []);

  return (
    <>
      <SEO title={'Privacy Policy'} />
      <PrivacyWrap>
        <TitleSection>
          <EnHeading>
            Privacy <br className="only-sp" />
            Policy
          </EnHeading>
        </TitleSection>

        <PrivacySectionWrap>
          <PrivacySection>
            <TextSet>
              <JaHeading size={'middle'}>個人情報保護に関する方針</JaHeading>
              <Text>
                当社および当社グループ企業では、デジタルデザイン業務、ウェブ制作関連業務、ウェブコンテンツ運用業務、ウェブサービス業務、クリエイター人材支援業務を行っており、その事業活動を通じて取得する個人情報（以下、「個人情報」という。）を扱っております。
              </Text>
              <Text>
                その個人情報を確実に保護することは、当社の重要な社会的責務と認識しており、個人情報保護に関する法令、国が定める指針その他の規範を遵守し、個人情報を正確かつ誠実に取り扱うため、以下に掲げた事項を念頭に置き、お客さまの個人情報保護に万全を尽くしてまいります。
              </Text>
            </TextSet>

            <TextSet>
              <JaHeading size={'small'}>1. 個人情報の取得及び利用目的</JaHeading>

              <Text>
                個人情報保護の重要性を従業者一同が認識し、当社の事業目的を遂行する範囲内で、適法かつ公正な手段によって、これを取得・利用します。また、個人情報の目的外利用については一切これを行いません。目的外利用の必要が生じた場合は、新たな利用目的について、あらかじめご本人の同意を得た上で利用します。なお、個人情報の保護に関する法律（以下、「法」といいます。）第18条における当社の取り扱う個人情報については以下の通りです。
              </Text>

              <JaHeading size={'xsmall'}>業務上で取り扱う個人情報</JaHeading>

              <List>
                <li>営業活動や業務における顧客・委託先との連携のため</li>
                <li>サービスデザインや開発・制作などにおけるユーザー調査・インタビューのため</li>
                <li>新規サービス開発時のテスト利用のため</li>
                <li>セミナーやイベントの開催、参加者への連絡のため</li>
                <li>社内外へ発信するコンテンツや記録資料としての保存・活用のため</li>
              </List>

              <JaHeading size={'xsmall'}>採用活動に関する個人情報</JaHeading>

              <List>
                <li>グループ企業を含む採用・選考活動における、求職者への連絡、契約、応募履歴の確認のため</li>
              </List>

              <JaHeading size={'xsmall'}>当社従業員情報</JaHeading>

              <List>
                <li>グループ企業を含む従業員の人事労務管理、業務管理、健康管理、セキュリティ管理のため</li>
                <li>番号法に定められた利用目的のため（特定個人情報）</li>
              </List>

              <JaHeading size={'xsmall'}>その他</JaHeading>

              <List>
                <li>当社への問い合わせの対応のため</li>
              </List>
            </TextSet>

            <TextSet>
              <JaHeading size={'small'}>2. 個人情報の第三者への提供や委託</JaHeading>

              <Text>
                当社は、当社が保有する個人情報について、個人情報保護法等の法令に定めのある場合を除き、ご本人の同意を得ることなく第三者に提供・委託することはいたしません。
                <br />
                ご本人の同意を得て個人情報の取り扱いの全部または一部を第三者に委託する場合は、当該第三者について厳正な調査を行い、取り扱いを委託された個人情報の安全管理が図られるよう当該第三者に対する必要かつ適切な監督を行います。
              </Text>
            </TextSet>

            <TextSet>
              <JaHeading size={'small'}>3. 個人を特定しない情報の第三者への提供</JaHeading>

              <Text>
                個人を特定しないクライアント環境情報、属性情報および行動履歴の取得・保存について、第三者へ提供する場合、あくまで統計的な情報の提供に留め、個人情報の提供は行いません。
                <br />
                ただし、次に掲げる場合は上記に定める第三者には該当しないものとします。
              </Text>

              <List>
                <li>当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合</li>
                <li>合併その他の事由による事業の承継に伴って個人情報が提供される場合</li>
              </List>
            </TextSet>

            <TextSet>
              <JaHeading size={'small'}>4. 個人情報の共同利用</JaHeading>

              <Text>
                当社は、当社が保有する個人情報を、グループ企業との協業において必要な範囲のみに限定し、共同利用をする場合があります。
              </Text>
            </TextSet>

            <TextSet>
              <JaHeading size={'small'}>5. 個人情報に関する法令の遵守方針</JaHeading>

              <Text>
                当社は、個人情報に関する法令、国が定める指針その他の規範（以下、「法令等」という。）及び個人情報保護マネジメントシステムを遵守し、個人情報を誠実に取り扱います。また、法令等を常に把握することに努め、当社事業に従事する従業者（以下、「従業者」という。）に周知し順守いたします。
              </Text>
            </TextSet>

            <TextSet>
              <JaHeading size={'small'}>6. 個人情報に対するリスク管理方針 ・安全管理措置</JaHeading>

              <Text>
                当社は、個人情報の漏えい、滅失又はき損等の危険に対し、内部規律の整備、組織的、人的、物理的、技術的な安全管理措置を講じます。また、定期的な点検や外部環境の把握を実施し、発見された違反、事件及び事故に対して、法令に則り速やかに報告、是正を行うとともに弱点に対する予防措置を実施し、従業者に安全に関する教育を行います。
              </Text>
            </TextSet>

            <TextSet>
              <JaHeading size={'small'}>7. 個人情報の開示等の権利と請求</JaHeading>

              <Text>
                ご本人の個人情報について、次の各号および当社が法令上義務を負わない場合を除き、当社所定の手続きにしたがって、開示、訂正、削除、利用停止又は消去を請求することができます。その際、ご本人であることを確認できない場合には開示に応じません。
                また、開示にあたっては所定の開示手数料をいただく場合があります。
              </Text>

              <List>
                <li>
                  当該個人情報の在否が明らかになることによって、本人又は第三者の生命、身体又は財産に危害が及ぶおそれのあるもの
                </li>
                <li>
                  当該個人情報の在否が明らかになることによって、違法又は不当な行為を助長し、又は誘発するおそれのあるもの
                </li>
                <li>
                  当該個人情報の在否が明らかになることによって、国の安全が害されるおそれ、他国若しくは国際機関との信頼関係が損なわれるおそれ又は他国若しくは国際機関との交渉上不利益を被るおそれのあるもの
                </li>
                <li>
                  当該個人情報の在否が明らかになることによって、犯罪の予防、鎮圧又は捜査その他の公共の安全と秩序維持に支障が及ぶおそれのあるもの
                </li>
              </List>
            </TextSet>

            <TextSet>
              <JaHeading size={'small'}>8. 開示等の求めに応じる手続き</JaHeading>

              <Text>
                個人情報の開示、訂正、削除、利用停止又は消去のご要望がある場合は､お問い合わせ窓口にご連絡ください｡
                請求の手続き方法やご本人様確認についての手順や必要書類等についてご案内いたします。
              </Text>
            </TextSet>

            <TextSet>
              <JaHeading size={'small'}>9. 個人情報を与えることの任意性</JaHeading>

              <Text>
                ご自身の個人情報について、弊社に提供することについては任意ですが、提供されない場合、お問い合わせ、お申し込み等の内容にご対応できない場合がございます。
              </Text>
            </TextSet>

            <TextSet>
              <JaHeading size={'small'}>10. 容易に認識できない方法による個人情報の取得</JaHeading>

              <Text>本人が容易に認識できない方法によって個人情報を取得することはありません。</Text>
            </TextSet>

            <TextSet>
              <JaHeading size={'small'}>11. 苦情及び相談に対する対応方針</JaHeading>

              <Text>
                当社の個人情報の取扱いに関する苦情及び相談については、常に適切に対応出来る体制を維持し、受け付け次第、適切かつ迅速な対応をいたします。
              </Text>
            </TextSet>

            <TextSet>
              <JaHeading size={'small'}>12. 個人情報保護に関する取組みの継続的改善</JaHeading>

              <Text>
                当社は、個人情報保護に関する取組みの継続的改善に努めます。したがって、この保護方針が予告なく変更されることがあります。
              </Text>
            </TextSet>

            <TextSet>
              <Text>
                制定年月日 : 平成20年8月13日
                <br />
                最終改定年月日 : 令和4年4月1日
                <br />
                株式会社 フォーデジット
                <br />
                代表取締役CEO 田口 亮
                <br />
                東京都港区赤坂八丁目5番32号
              </Text>
            </TextSet>

            <TextSet>
              <Text>
                <b>【個人情報の取り扱いに関するお問い合わせ先】</b>
                <br />
                株式会社 フォーデジット　個人情報保護窓口担当
                <br />
                e-mail : privacy@4digit.jp
                <br />
                受付時間　年末年始、全社休業期間を除く平日 10：00〜17：00
              </Text>
            </TextSet>
          </PrivacySection>
        </PrivacySectionWrap>
      </PrivacyWrap>

      <InquiryBlock />
    </>
  );
};

export default Pages;

export const pageQuery = graphql`
  query privacyQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
